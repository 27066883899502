<template>
  <div>
    <v-app style="min-height: 100vh !important">
      <div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                {{ $t("Warning_Message.Unauthorized") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogUnauthorized"
                >
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                {{ $t("Warning_Message.TimedOut_Error") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="closeDialogError">
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div style="margin-top: 5%">
        <h2 v-if="paymentGateway === 'kbzPay'"> {{ $t("Customer.QRCode.Payment_With_KBZPay") }}</h2>
        <h2 v-else>{{ $t("Customer.QRCode.Payment_With_AYAPay") }}</h2>

        <p style="margin-top: 10px">{{ $t("Customer.QRCode.Please_Complete_The_Payment_Within_5_Minutes") }}</p>
        <h3>{{ $t("Customer.QRCode.Amount_To_Be_Paid") }} : {{ amount }} MMK</h3>
      </div>
      <div class="action" v-if="qr_code">
        <div>
          <VueQRCodeComponent
            :value="qr_code"
            :size="size"
            level="H"
          ></VueQRCodeComponent>
        </div>
        <div>
          <p style="color: black; font-weight:800" v-if="paymentGateway === 'kbzPay'"> {{ $t("Customer.QRCode.How_To_Make_Payment_With_KBZPay") }} </p>
          <p style="color: black; font-weight:800" v-else> {{ $t("Customer.QRCode.How_To_Make_Payment_With_AYAPay") }} </p>

          <v-list dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon dense color="black" size="12px">
                  mdi-checkbox-blank-circle</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="text-align: left;font-size:16px" v-if="paymentGateway === 'kbzPay'"> {{ $t("Customer.QRCode.Open_KBZPay_App") }} </v-list-item-title>
                <v-list-item-title style="text-align: left;font-size:16px" v-else> {{ $t("Customer.QRCode.Open_AYAPay_App") }} </v-list-item-title>

              </v-list-item-content>
            </v-list-item>

             <v-list-item>
              <v-list-item-icon>
                <v-icon dense color="black" size="12px">
                  mdi-checkbox-blank-circle</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="text-align: left;font-size:16px">{{ $t("Customer.QRCode.Click_Scan_And_Pay") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>


            <v-list-item>
              <v-list-item-icon>
                <v-icon dense color="black" size="12px">
                  mdi-checkbox-blank-circle</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="text-align: left;font-size:16px">{{ $t("Customer.QRCode.Scan_QR_And_Complete_Payment") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

      </div>
      <div style="margin-top:2%">
       <div v-if="paymentStatus">
            <p>{{ $t("Customer.QRCode.Waiting_To_Complete_Pay") }}</p>
               <v-progress-circular
                     indeterminate
                      color="primary"
               ></v-progress-circular>
        </div>
         <div v-else>
              <img style="height:48px; width: 48px;" src="https://d3vsnl8idgwrlw.cloudfront.net/success_tick.svg"/>
            <p>Redirecting to success page</p>
        </div>
        </div>
    </v-app>
  </div>
</template>

<script>
import NavBar from "../../../views/navbar.vue";
import axios from "axios";
import bottomnavbar from "./bottomnavbar.vue";
import VueQRCodeComponent from "qrcode.vue";
export default {
  name: "QRCode",
  data() {
    return {
      booking_data: {},
      booking_id: "",
      qr_code: "",
      size: 300,
      currentCustomer: "",
      auth_error_enable: false,
      error_enable: false,
      query_order_flag: false,
      timeout_count: 0,
      isMobile: false,
      paymentStatus:true,
      amount: "",
      paymentGateway: "",
    };
  },
  components: {
    NavBar,
    VueQRCodeComponent,
    bottomnavbar,
  },
  methods: {
    closeDialogUnauthorized() {
      this.auth_error_enable = false;
      this.$router.push({
        name: "Login",
      });
    },
    closeDialogError() {
      this.error_enable = false;
      this.$router.push({
        name: "CustomerHomeScreen",
      });
    },
    query_order() {
      var query_order_function = setInterval(() => {
        var success = false;
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/kbzPayQueryOrderCall",
            this.booking_data
          )
          .then((queryResponse) => {
            var trade_status = queryResponse.data.data.trade_status;
            if (trade_status == "PAY_SUCCESS") {
                this.paymentStatus=false
              clearInterval(query_order_function);
              this.$router.push({
                path:
                  "bookedSuccess/?merch_order_id=" + String(this.booking_id),
              });
              localStorage.removeItem('BookingDetails')
            } else if (trade_status == "WAIT_PAY" || trade_status == "PAYING") {
              this.timeout_count += 1;
              if (this.timeout_count >= 20) {
                // window.alert("Something went wrong...");
                clearInterval(query_order_function);
                this.error_enable = true;
                // var close_order_data = {
                //     "token" : this.currentCustomer,
                //     "typeOfUser" : "CUSTOMER",
                //     "booking_id" : this.booking_id
                // }
                // axios.post(process.env.VUE_APP_BACKEND_URL + "/kbzPayCloseOrderCall", close_order_data)
                // .then((close_order_response) => {
                //     console.log("Close Order successful : ", close_order_response);
                // })
                // .catch((close_order_error) => {
                //     console.log("Close Order Failed : ", close_order_error);
                //     this.error_enable = true;
                // });
                localStorage.removeItem('BookingDetails')
                this.$router.push({
                  name: "CustomerHomeScreen",
                });
              }
            } else {
              // window.alert("Something went wrong...");
              localStorage.removeItem('BookingDetails')
              clearInterval(query_order_function);
              this.error_enable = true;
              // var close_order_data = {
              //     "token" : this.currentCustomer,
              //     "typeOfUser" : "CUSTOMER",
              //     "booking_id" : this.booking_id
              // }
              // axios.post(process.env.VUE_APP_BACKEND_URL + "/kbzPayCloseOrderCall", close_order_data)
              // .then((close_order_response) => {
              //     console.log("Close Order successful : ", close_order_response);
              // })
              // .catch((close_order_error) => {
              //     console.log("Close Order Failed : ", close_order_error);
              //     this.error_enable = true;
              // });
            }
          })
          .catch((error) => {
            this.error_enable = true;
            localStorage.removeItem('BookingDetails')
          });
      }, 15000);
    },
  },
  mounted() {
    this.isMobile = this.$device.mobile;
    if(this.$store.state.locale == 'en')
      document.title = "QR Code Payment"
    else
      document.title = 'QR ဖြင့်ငွေပေးချေမှု'
    this.currentCustomer = this.$cookies.get("customerToken");
    var access = this.$cookies.get("access");
    if (!this.currentCustomer) {
      this.$router.push({
        name: "Login",
      });
    } else {
      this.booking_id = this.$route.params.booking_id;
      this.qr_code = this.$route.params.qr_code;
      this.amount = this.$route.params.amount;
      this.paymentGateway = this.$route.params.paymentGateway
      this.booking_data = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER",
        booking_id: this.booking_id,
        // trade_status: "",
      };
      this.query_order();
    }
  },
};
</script>

<style scoped>
.action {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
</style>